import { Flex, useMediaQuery } from '@chakra-ui/react';
import Briefings from './Briefings';
import Categories from './Categories';
import LastArticles from './LastArticles';

const SideElements = ({ global }) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  return (
    <>
      <Flex
        display={isLargerThan1024 ? 'flex' : 'none'}
        maxW={400}
        direction="column"
        className="uk-section"
      >
        {/* Latests Postss */}
        <LastArticles />
        {/* All Categories */}
        <Categories />
        {/* Anders Pink Briefing */}
        <Briefings global={global} />
      </Flex>
    </>
  );
};

export default SideElements;
