import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories(
    $filters: CategoryFiltersInput
    $pagination: PaginationArg
  ) {
    categories(filters: $filters) {
      data {
        attributes {
          name
          slug
          articles(pagination: $pagination) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
