import { Flex, Box, Text, Divider } from '@chakra-ui/react';

const Section = ({ title, children }) => {
  return (
    <Flex
      shadow="md"
      direction="column"
      minW={350}
      maxW={450}
      borderWidth={1}
      rounded="md"
    >
      <Box p={5}>
        <Divider
          w={10}
          mb={2}
          borderWidth={2}
          opacity={1}
          borderColor="#cf0032"
        />
        <Box>
          <Text fontWeight="900" fontSize={20}>
            {title}
          </Text>
        </Box>
      </Box>
      <Box mt={5}>{children}</Box>
    </Flex>
  );
};

export default Section;
