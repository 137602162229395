import { useState } from 'react';
import { Flex, Icon, Box, Text, Image } from '@chakra-ui/react';
import moment from 'moment';
import { GoPrimitiveDot } from 'react-icons/go';
import { useQuery } from '@apollo/client';
import { capitalizeString } from '../../lib/capitaliseString';
import { getStrapiMedia } from '../../lib/media';
import { getDurationInSeconds } from '../../lib/reader';
import Section from '../section';
import { GET_ARTICLES } from '../../apollo/queries/GetArticles';
import { trucateString } from '../../lib/trucateString';
import Link from 'next/link';

const LastArticles = () => {
  const [articles, setArticles] = useState(null);

  const { data, loading, error, networkStatus } = useQuery(GET_ARTICLES, {
    context: { clientName: 'strapi' },
    variables: {
      sort: ['createdAt:DESC'],
      pagination: { page: 1, pageSize: 10 },
    },
    fetchPolicy: 'cache-first',
  });

  if (!loading && !articles && data.articles.data.length > 0) {
    setArticles(data.articles.data);
  }

  return (
    <Box mb={10}>
      {articles && (
        <Section title="Latest Posts">
          {articles.map((item, i) => {
            let readingTime = Math.ceil(
              getDurationInSeconds(item.attributes.content, null)
            );
            return (
              <Link
                key={i}
                href={`/article/${item.attributes.slug}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Flex
                  direction="row"
                  px={5}
                  py={2}
                  cursor="pointer"
                  alignItems="center"
                  _hover={{ background: 'gray.100' }}
                >
                  <Box width={175} height={102} rounded="md" overflow="hidden">
                    <Image
                      src={getStrapiMedia(item.attributes.image)}
                      alt="banner"
                      width={300}
                      height={300}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  <Flex direction="column" pl={5} width={400}>
                    <Text fontSize={16} fontWeight="900" noOfLines={3}>
                      {item.attributes.title &&
                        trucateString(
                          capitalizeString(item.attributes.title),
                          80
                        )}
                    </Text>

                    <Flex alignItems="center">
                      <Icon my={2} color="#cf0032" as={GoPrimitiveDot} />
                      <Text pl={1} pr={1} fontSize={14} fontWeight="300">
                        {moment(item.attributes.publishedAt).format(
                          'MMM DD, YYYY'
                        )}
                      </Text>
                      <Text fontWeight="900" fontSize={12} pl={2}>
                        {readingTime} min read
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Link>
            );
          })}
        </Section>
      )}
    </Box>
  );
};

export default LastArticles;
