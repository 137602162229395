type Category = {
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  count: number;
};

type CategoryData = {
  attributes: {
    articles: { data: string[] };
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  };
  id: string;
};

type Data = {
  categories: { data: CategoryData[] };
};

export const countArticlesInCategories = (data: Data): Category[] => {
  const categories: Category[] = data.categories.data.map(
    (category: CategoryData) => {
      const count = category.attributes.articles.data.length;
      return {
        id: category.id,
        name: category.attributes.name,
        slug: category.attributes.slug,
        createdAt: category.attributes.createdAt,
        updatedAt: category.attributes.updatedAt,
        count: isNaN(count) ? 0 : count,
      };
    }
  );

  return categories;
};
