import axios from 'axios';
import moment from 'moment';
import router from 'next/router';
import { Key, useEffect, useState } from 'react';
import { Flex, Box, Icon, Text, Image } from '@chakra-ui/react';
import { GoPrimitiveDot } from 'react-icons/go';
import Section from '../section';
import { capitalizeString } from '../../lib/capitaliseString';
import { trucateString } from '../../lib/trucateString';
import Link from 'next/link';

const Briefings = ({ global }) => {
  const [briefing, setBriefing] = useState(null);

  useEffect(() => {
    if (global) {
      axios
        .post('/briefing', {
          data: global.attributes.andersPink,
        })
        .then(({ data }) => {
          setBriefing(data);
        });
    }
  }, [global]);

  return (
    <Box>
      {briefing && (
        <Section
          title={briefing?.name ? `Briefing - ${briefing?.name}` : 'Briefing'}
        >
          {Object.keys(briefing).length > 0 &&
            briefing?.articles.map((item, i: Key) => {
              return (
                <Link
                  key={i}
                  href={`${item.url}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <Flex
                    direction="row"
                    px={5}
                    py={2}
                    cursor="pointer"
                    alignItems="center"
                    _hover={{ background: 'gray.100' }}
                  >
                    <Box
                      width={175}
                      height={102}
                      rounded="md"
                      overflow="hidden"
                    >
                      <Image
                        src={item.image}
                        alt="banner"
                        width={300}
                        height={300}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                    <Flex direction="column" pl={5} width={400}>
                      <Text fontSize={16} fontWeight="900" noOfLines={3}>
                        {trucateString(capitalizeString(item.title), 80)}
                      </Text>

                      <Flex alignItems="center">
                        <Icon my={2} color="#cf0032" as={GoPrimitiveDot} />
                        <Text pl={1} pr={1} fontSize={14} fontWeight="300">
                          {moment(item.date_published).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontWeight="900" fontSize={12} pl={2}>
                          {Math.ceil(item.reading_time / 60)} min read
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Link>
              );
            })}
        </Section>
      )}
    </Box>
  );
};

export default Briefings;
