import { useQuery } from '@apollo/client';
import { Flex, Button, Text, Box } from '@chakra-ui/react';
import Link from 'next/link';
import router from 'next/router';
import { useState } from 'react';
import { GET_CATEGORIES } from '../../apollo/queries/GetCategories';
import { capitalizeString } from '../../lib/capitaliseString';
import { countArticlesInCategories } from '../../lib/countArticlesInCategories';
import Section from '../section';

const Categories = () => {
  const [seeMore, setSeeMore] = useState(false);

  const { data, loading, error, networkStatus } = useQuery(GET_CATEGORIES, {
    context: { clientName: 'strapi' },
    variables: {
      pagination: {
        pageSize: 100,
      },
    },
    fetchPolicy: 'cache-first',
  });

  let categories = [];
  if (!loading) {
    categories = countArticlesInCategories(data);
  }

  const allCategories = categories.map((category) => {
    return {
      name: category.name,
      slug: category.slug,
      count: category.count,
    };
  });

  const filteredCategories = allCategories
    .filter((category) => category.count !== 0)
    .sort((c1: any, c2: any) =>
      c1.count < c2.count ? 1 : c1.count > c2.count ? -1 : 0
    );

  if (filteredCategories.length >= 10) {
    setSeeMore(true);
  }

  return (
    <Box mb={10}>
      {filteredCategories && (
        <Section title="Categories">
          {filteredCategories.slice(0, 9).map((item, i) => (
            <Link
              key={i}
              href={`/categories/[category]`}
              as={`/categories/${item.slug}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Flex
                direction="row"
                justifyContent="space-between"
                px={5}
                py={5}
                rounded="md"
                alignItems="center"
                cursor="pointer"
              >
                <Text
                  fontSize={14}
                  fontWeight="600"
                  backgroundColor="#cf0032"
                  color="white"
                  px={2}
                  py={1}
                  rounded="xl"
                >
                  {capitalizeString(item.name)}
                </Text>
                <Text fontSize={14} fontWeight="600">
                  {item.count} Posts
                </Text>
              </Flex>
            </Link>
          ))}
          <Flex justify={'center'}>
            {seeMore && (
              <Link href={'/categories'} style={{ textDecoration: 'none' }}>
                <Button
                  background="white"
                  color="#cf0032"
                  borderWidth={1}
                  borderColor="#cf0032"
                  rounded={3}
                  _hover={{ color: 'black' }}
                >
                  See More
                </Button>
              </Link>
            )}
          </Flex>
        </Section>
      )}
    </Box>
  );
};

export default Categories;
